var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        attrs: { action: "" },
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.validateLoginForm.apply(null, arguments)
          },
        },
      },
      [
        _vm._m(0),
        _vm._v(" "),
        _vm.carriedOverMessage && _vm.carriedOverMessage.action === "confirm"
          ? _c(
              "div",
              {
                staticClass: "msgblock alert",
                class: `alert-${_vm.carriedOverMessage.type}`,
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.carriedOverMessage.content) + "\n    "
                ),
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.response &&
        _vm.response.status === 401 &&
        _vm.response.message.includes("Password expired")
          ? _c("div", { staticClass: "alert msgblock invalidCredentials" }, [
              _c("i", {
                staticClass: "fa fa-exclamation-circle",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n        " + _vm._s(_vm.response.message) + ".\n      "
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.response &&
        _vm.response.status === 401 &&
        !_vm.response.message.includes("Password expired")
          ? _c("div", { staticClass: "alert msgblock invalidCredentials" }, [
              _c("i", {
                staticClass: "fa fa-exclamation-circle",
                attrs: { "aria-hidden": "true" },
              }),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n        Invalid username or password. If you don't have an account with " +
                    _vm._s(_vm.$root.domainName) +
                    ", please\n        "
                ),
                _c(
                  "a",
                  {
                    staticClass: "btn-log-in",
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.$parent.$emit("change-modal", "signup")
                      },
                    },
                  },
                  [_vm._v("create one.")]
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.response && _vm.response.type && _vm.response.status !== 401
          ? _c(
              "div",
              {
                staticClass: "alert msgblock",
                class: `alert-${_vm.response.type}`,
              },
              [_vm._v("\n      " + _vm._s(_vm.response.message) + "\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "form-group",
            class: { "has-error": _vm.errors.has("email") },
          },
          [
            _c("label", { attrs: { for: "email" } }, [_vm._v("Email Address")]),
            _vm._v(" "),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|email",
                    expression: "'required|email'",
                  },
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.formData.username,
                    expression: "formData.username",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  type: "email",
                  name: "email",
                  placeholder: "Email Address",
                  autocomplete: "username",
                },
                domProps: { value: _vm.formData.username },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.formData, "username", $event.target.value)
                  },
                },
              }),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("email"),
                    expression: "errors.has('email')",
                  },
                ],
                staticClass: "text-danger",
              },
              [
                _c("i", { staticClass: "fa fa-exclamation-circle" }),
                _vm._v("\n        " + _vm._s(_vm.errors.first("email"))),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "form-group",
            class: { "has-error": _vm.errors.has("password") },
          },
          [
            _c("label", { attrs: { for: "password" } }, [_vm._v("Password")]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group" }, [
              (_vm.showPassword ? "text" : "password") === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.password,
                        expression: "formData.password",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "password",
                      placeholder: "Password",
                      autocomplete: "current-password",
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.formData.password)
                        ? _vm._i(_vm.formData.password, null) > -1
                        : _vm.formData.password,
                    },
                    on: {
                      change: function ($event) {
                        var $$a = _vm.formData.password,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.formData,
                                "password",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.formData,
                                "password",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.formData, "password", $$c)
                        }
                      },
                    },
                  })
                : (_vm.showPassword ? "text" : "password") === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.password,
                        expression: "formData.password",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "password",
                      placeholder: "Password",
                      autocomplete: "current-password",
                      type: "radio",
                    },
                    domProps: { checked: _vm._q(_vm.formData.password, null) },
                    on: {
                      change: function ($event) {
                        return _vm.$set(_vm.formData, "password", null)
                      },
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.formData.password,
                        expression: "formData.password",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "password",
                      placeholder: "Password",
                      autocomplete: "current-password",
                      type: _vm.showPassword ? "text" : "password",
                    },
                    domProps: { value: _vm.formData.password },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.formData, "password", $event.target.value)
                      },
                    },
                  }),
              _vm._v(" "),
              _c("span", { staticClass: "input-group-btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: { click: _vm.toggleShow },
                  },
                  [
                    _c("i", {
                      staticClass: "fa",
                      class: _vm.showPassword ? "fa-eye-slash" : "fa-eye",
                    }),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("password"),
                    expression: "errors.has('password')",
                  },
                ],
                staticClass: "text-danger",
              },
              [
                _c("i", { staticClass: "fa fa-exclamation-circle" }),
                _vm._v("\n        " + _vm._s(_vm.errors.first("password"))),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group text-center" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-block btn-orange",
              attrs: {
                type: "submit",
                disabled: _vm.errors.any() || _vm.processing,
              },
            },
            [
              _vm._v("\n        Sign In\n        "),
              _c("i", {
                staticClass: "fa",
                class: {
                  "fa-spinner fa-spin": _vm.processing,
                  "fa-arrow-right": !_vm.processing,
                },
                attrs: { "aria-hidden": "true" },
              }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "forgot-password" }, [
            _c(
              "a",
              {
                staticClass: "forgot-password btn-forgot-password",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$parent.$emit("change-modal", "forgot")
                  },
                },
              },
              [_vm._v("\n          Forgot your password?\n        ")]
            ),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-signup",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$parent.$emit("change-modal", "signup")
                },
              },
            },
            [
              _vm._v("\n        Don't have an account? "),
              _c("span", [_vm._v("Sign up")]),
            ]
          ),
        ]),
        _vm._v(" "),
        _vm._m(1),
        _vm._v(" "),
        _c("div", { staticClass: "form-group btns-sign-in" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-block btn-google",
              attrs: { href: _vm.socialLoginUrl("google"), target: "_self" },
            },
            [_c("strong", [_vm._v("Google")])]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-block btn-facebook",
              attrs: { href: _vm.socialLoginUrl("facebook"), target: "_self" },
            },
            [_c("strong", [_vm._v("Facebook")])]
          ),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "form-group btns-sign-in mobile-sign-in" }, [
          _c(
            "a",
            {
              staticClass: "btn btn-block btn-google",
              attrs: { href: _vm.socialLoginUrl("google"), target: "_self" },
            },
            [
              _c("i", {
                staticClass: "fa fa-google-plus",
                attrs: { "aria-hidden": "true" },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "btn btn-block btn-facebook",
              attrs: { href: _vm.socialLoginUrl("facebook"), target: "_self" },
            },
            [
              _c("i", {
                staticClass: "fa fa-facebook",
                attrs: { "aria-hidden": "true" },
              }),
            ]
          ),
        ]),
        _vm._v(" "),
        _c("p", [
          _vm._v(
            "By logging in, you agree to the " +
              _vm._s(_vm.$root.domainName) +
              "'s "
          ),
          _c(
            "a",
            { attrs: { target: "_blank", href: "/terms-and-policies" } },
            [_vm._v("Privacy Policy and\n        Terms of Use")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "form-group title-group" }, [
      _c("h2", [_vm._v("Sign into your NIC Industries Account")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "divider-sign-in" }, [
      _c("span", [_vm._v("OR SIGN IN WITH")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }