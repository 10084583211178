var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitForm.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "form-group" }, [
          _vm.response.type
            ? _c(
                "div",
                { staticClass: "alert", class: `alert-${_vm.response.type}` },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.response.message) + "\n      "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("h2", { staticClass: "resetForm" }, [_vm._v("Change Password")]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "form-group resetForm",
            class: { "has-error": _vm.errors.has("password") },
          },
          [
            _c("label", { attrs: { for: "password" } }, [
              _vm._v("Current Password"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.resetPasswordForm.current_password,
                  expression: "resetPasswordForm.current_password",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "password",
                name: "current password",
                id: "current_password",
              },
              domProps: { value: _vm.resetPasswordForm.current_password },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(
                    _vm.resetPasswordForm,
                    "current_password",
                    $event.target.value
                  )
                },
              },
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.response.type === "danger",
                    expression: "response.type === 'danger'",
                  },
                ],
                staticClass: "text-danger",
              },
              [
                _c("i", { staticClass: "fa fa-exclamation-circle" }),
                _vm._v(" " + _vm._s(_vm.response.message)),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "form-group resetForm",
            class: { "has-error": _vm.errors.has("password") },
          },
          [
            _c("label", { attrs: { for: "password" } }, [
              _vm._v("New Password"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.resetPasswordForm.new_password,
                  expression: "resetPasswordForm.new_password",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "password",
                name: "password",
                id: "new_password",
                autocomplete: "new-password",
              },
              domProps: { value: _vm.resetPasswordForm.new_password },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(
                      _vm.resetPasswordForm,
                      "new_password",
                      $event.target.value
                    )
                  },
                  _vm.password_check,
                ],
              },
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("password"),
                    expression: "errors.has('password')",
                  },
                ],
                staticClass: "text-danger",
              },
              [
                _c("i", { staticClass: "fa fa-exclamation-circle" }),
                _vm._v(" " + _vm._s(_vm.errors.first("password"))),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "form-group resetForm",
            class: { "has-error": _vm.errors.has("confirmPassword") },
          },
          [
            _c("label", { attrs: { for: "password" } }, [
              _vm._v("Confirm New Password"),
            ]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required",
                  expression: "'required'",
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.confirmPassword,
                  expression: "confirmPassword",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "password",
                name: "password confirmation",
                id: "confirm_password",
                autocomplete: "new-password",
              },
              domProps: { value: _vm.confirmPassword },
              on: {
                input: [
                  function ($event) {
                    if ($event.target.composing) return
                    _vm.confirmPassword = $event.target.value
                  },
                  _vm.password_check,
                ],
              },
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("Password confirmation"),
                    expression: "errors.has('Password confirmation')",
                  },
                ],
                staticClass: "text-danger",
              },
              [
                _c("i", { staticClass: "fa fa-exclamation-circle" }),
                _vm._v(" " + _vm._s(_vm.errors.first("Password confirmation"))),
              ]
            ),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.pwErrors,
                    expression: "pwErrors",
                  },
                ],
                staticClass: "text-danger",
              },
              [
                _c("i", { staticClass: "fa fa-exclamation-circle" }),
                _vm._v("\n        " + _vm._s(_vm.errorMsg) + "\n      "),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        !_vm.hasSentPWReset
          ? _c("PasswordValidation", {
              ref: "pwValidation",
              attrs: {
                newPassword: _vm.resetPasswordForm.new_password,
                confirmPassword: _vm.confirmPassword,
              },
              on: {
                "validate-password": _vm.setIsValidPassword,
                "password-errors": _vm.setPWErrors,
                "error-msg": _vm.setErrorMsg,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "form-group resetForm text-center" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-block btn-orange",
              attrs: { type: "submit", disabled: !_vm.isValidPassword },
            },
            [
              _vm._v("\n        Submit "),
              _c("i", {
                staticClass: "fa",
                class: {
                  "fa-spinner fa-spin": _vm.resetPasswordForm.processing,
                  "fa-arrow-right": !_vm.resetPasswordForm.processing,
                },
                attrs: { "aria-hidden": "true" },
              }),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }