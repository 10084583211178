var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitForm.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "form-group" }, [
          _vm.showExpiredError
            ? _c("div", { staticClass: "alert alert-danger" }, [
                _vm._v(
                  "\n        Token expired. Please request a new password reset token\n        "
                ),
                _c(
                  "a",
                  {
                    attrs: { href: "#" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.changeTo("forgot")
                      },
                    },
                  },
                  [_vm._v("here")]
                ),
                _vm._v(".\n      "),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.response.type
            ? _c(
                "div",
                { staticClass: "alert", class: `alert-${_vm.response.type}` },
                [
                  _vm._v(
                    "\n        " + _vm._s(_vm.response.message) + "\n        "
                  ),
                  _vm.response.type === "success"
                    ? _c(
                        "a",
                        {
                          attrs: { href: "#" },
                          on: {
                            click: function ($event) {
                              return _vm.changeTo("signin")
                            },
                          },
                        },
                        [_vm._v("\n          Click here to log in.\n        ")]
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _c("h2", { staticClass: "resetForm" }, [_vm._v("Reset Password")]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "form-group resetForm",
            class: { "has-error": _vm.errors.has("password") },
          },
          [
            _c("label", { attrs: { for: "password" } }, [
              _vm._v("New Password"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group" }, [
              (_vm.showPassword ? "text" : "password") === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.resetPasswordForm.new_password,
                        expression: "resetPasswordForm.new_password",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "password",
                      id: "password",
                      autocomplete: "new-password",
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.resetPasswordForm.new_password)
                        ? _vm._i(_vm.resetPasswordForm.new_password, null) > -1
                        : _vm.resetPasswordForm.new_password,
                    },
                    on: {
                      input: _vm.password_check,
                      change: function ($event) {
                        var $$a = _vm.resetPasswordForm.new_password,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 &&
                              _vm.$set(
                                _vm.resetPasswordForm,
                                "new_password",
                                $$a.concat([$$v])
                              )
                          } else {
                            $$i > -1 &&
                              _vm.$set(
                                _vm.resetPasswordForm,
                                "new_password",
                                $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                              )
                          }
                        } else {
                          _vm.$set(_vm.resetPasswordForm, "new_password", $$c)
                        }
                      },
                    },
                  })
                : (_vm.showPassword ? "text" : "password") === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.resetPasswordForm.new_password,
                        expression: "resetPasswordForm.new_password",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "password",
                      id: "password",
                      autocomplete: "new-password",
                      type: "radio",
                    },
                    domProps: {
                      checked: _vm._q(_vm.resetPasswordForm.new_password, null),
                    },
                    on: {
                      input: _vm.password_check,
                      change: function ($event) {
                        return _vm.$set(
                          _vm.resetPasswordForm,
                          "new_password",
                          null
                        )
                      },
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.resetPasswordForm.new_password,
                        expression: "resetPasswordForm.new_password",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "password",
                      id: "password",
                      autocomplete: "new-password",
                      type: _vm.showPassword ? "text" : "password",
                    },
                    domProps: { value: _vm.resetPasswordForm.new_password },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.resetPasswordForm,
                            "new_password",
                            $event.target.value
                          )
                        },
                        _vm.password_check,
                      ],
                    },
                  }),
              _vm._v(" "),
              _c("span", { staticClass: "input-group-btn" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-default",
                    attrs: { type: "button" },
                    on: { click: _vm.toggleShow },
                  },
                  [
                    _c("i", {
                      staticClass: "fa",
                      class: _vm.showPassword ? "fa-eye-slash" : "fa-eye",
                    }),
                  ]
                ),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("password"),
                    expression: "errors.has('password')",
                  },
                ],
                staticClass: "text-danger",
              },
              [
                _c("i", { staticClass: "fa fa-exclamation-circle" }),
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.errors.first("password")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.resetPasswordForm.token,
                  expression: "resetPasswordForm.token",
                },
              ],
              staticClass: "form-control",
              attrs: { type: "hidden", id: "token" },
              domProps: { value: _vm.resetPasswordForm.token },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.resetPasswordForm, "token", $event.target.value)
                },
              },
            }),
          ]
        ),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "form-group resetForm",
            class: { "has-error": _vm.pwErrors },
          },
          [
            _c("label", { attrs: { for: "password_confirmation" } }, [
              _vm._v("Confirm Password"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "input-group" }, [
              (_vm.showPassword ? "text" : "password") === "checkbox"
                ? _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.confirmPassword,
                        expression: "confirmPassword",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "Password Confirmation",
                      id: "password_confirmation",
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.confirmPassword)
                        ? _vm._i(_vm.confirmPassword, null) > -1
                        : _vm.confirmPassword,
                    },
                    on: {
                      input: _vm.password_check,
                      change: function ($event) {
                        var $$a = _vm.confirmPassword,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.confirmPassword = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.confirmPassword = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.confirmPassword = $$c
                        }
                      },
                    },
                  })
                : (_vm.showPassword ? "text" : "password") === "radio"
                ? _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.confirmPassword,
                        expression: "confirmPassword",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "Password Confirmation",
                      id: "password_confirmation",
                      type: "radio",
                    },
                    domProps: { checked: _vm._q(_vm.confirmPassword, null) },
                    on: {
                      input: _vm.password_check,
                      change: function ($event) {
                        _vm.confirmPassword = null
                      },
                    },
                  })
                : _c("input", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'",
                      },
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.confirmPassword,
                        expression: "confirmPassword",
                      },
                    ],
                    staticClass: "form-control",
                    attrs: {
                      name: "Password Confirmation",
                      id: "password_confirmation",
                      type: _vm.showPassword ? "text" : "password",
                    },
                    domProps: { value: _vm.confirmPassword },
                    on: {
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.confirmPassword = $event.target.value
                        },
                        _vm.password_check,
                      ],
                    },
                  }),
              _vm._v(" "),
              _c(
                "span",
                {
                  staticClass: "input-group-btn",
                  attrs: { autocomplete: "new-password" },
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-default",
                      attrs: { type: "button" },
                      on: { click: _vm.toggleShow },
                    },
                    [
                      _c("i", {
                        staticClass: "fa",
                        class: _vm.showPassword ? "fa-eye-slash" : "fa-eye",
                      }),
                    ]
                  ),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.pwErrors,
                    expression: "pwErrors",
                  },
                ],
                staticClass: "text-danger",
              },
              [
                _c("i", { staticClass: "fa fa-exclamation-circle" }),
                _vm._v("\n        " + _vm._s(_vm.errorMsg) + "\n      "),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        !_vm.hasSentPWReset
          ? _c("PasswordValidation", {
              ref: "pwValidation",
              attrs: {
                newPassword: _vm.resetPasswordForm.new_password,
                confirmPassword: _vm.confirmPassword,
              },
              on: {
                "validate-password": _vm.setIsValidPassword,
                "password-errors": _vm.setPWErrors,
                "error-msg": _vm.setErrorMsg,
              },
            })
          : _vm._e(),
        _vm._v(" "),
        _c("div", { staticClass: "form-group resetForm text-center" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-block btn-orange",
              attrs: { type: "submit", disabled: !_vm.isValidPassword },
            },
            [
              _vm._v("\n        Submit\n        "),
              _c("i", {
                staticClass: "fa",
                class: {
                  "fa-spinner fa-spin": _vm.resetPasswordForm.processing,
                  "fa-arrow-right": !_vm.resetPasswordForm.processing,
                },
                attrs: { "aria-hidden": "true" },
              }),
            ]
          ),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }