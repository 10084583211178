var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    { attrs: { id: "informationModal" } },
    [
      _vm.title
        ? _c("template", { slot: "header" }, [
            _c(
              "button",
              {
                staticClass: "close close-popup",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-label": "Close",
                },
              },
              [
                _c(
                  "span",
                  {
                    attrs: { "aria-hidden": "true" },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.cancelAction.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("×")]
                ),
              ]
            ),
            _vm._v(" "),
            _c("h2", { staticClass: "modal-title" }, [
              _vm._v(_vm._s(_vm.title)),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c("p", { domProps: { innerHTML: _vm._s(_vm.message) } }),
      _vm._v(" "),
      _vm.okCancel
        ? _c("template", { slot: "footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-orange",
                attrs: { type: "button", disabled: _vm.loading },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.okAction.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v("\n      " + _vm._s(_vm.okCancelText[0]) + "\n      "),
                _vm.loading
                  ? _c("i", { staticClass: "fa fa-spin fa-spinner" })
                  : _vm._e(),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn btn-orange",
                attrs: { type: "button", disabled: _vm.loading },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.cancelAction.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v("\n      " + _vm._s(_vm.okCancelText[1]) + "\n      "),
                _vm.loading
                  ? _c("i", { staticClass: "fa fa-spin fa-spinner" })
                  : _vm._e(),
              ]
            ),
          ])
        : _c("template", { slot: "footer" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-orange",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-label": "Close",
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.cancelAction.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Close")]
            ),
          ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }