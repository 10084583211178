import { render, staticRenderFns } from "./ForgotPassword.vue?vue&type=template&id=3bc4a324&scoped=true&"
import script from "./ForgotPassword.vue?vue&type=script&lang=js&"
export * from "./ForgotPassword.vue?vue&type=script&lang=js&"
import style0 from "./ForgotPassword.vue?vue&type=style&index=0&id=3bc4a324&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3bc4a324",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/jobs/Cerakote Staging/workspace/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('3bc4a324')) {
      api.createRecord('3bc4a324', component.options)
    } else {
      api.reload('3bc4a324', component.options)
    }
    module.hot.accept("./ForgotPassword.vue?vue&type=template&id=3bc4a324&scoped=true&", function () {
      api.rerender('3bc4a324', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/assets/js/components/authentication/ForgotPassword.vue"
export default component.exports