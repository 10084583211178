var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitForm.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "form-group" }, [
          _c("h2", [_vm._v("Forgot Password")]),
          _vm._v(" "),
          _c("h3", [
            _c(
              "a",
              {
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$parent.$emit("change-modal", "signin")
                  },
                },
              },
              [
                _c("i", {
                  staticClass: "fa fa-chevron-left",
                  attrs: { "aria-hidden": "true" },
                }),
                _vm._v("\n          Back to Sign in\n        "),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _vm.response.type
          ? _c(
              "div",
              {
                staticClass: "msgBlock alert",
                class: `alert-${_vm.response.type}`,
                staticStyle: { float: "left" },
              },
              [_vm._v("\n      " + _vm._s(_vm.response.message) + "\n    ")]
            )
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "form-group forgotPassword",
            class: { "has-error": _vm.errors.has("email") },
          },
          [
            _c("label", { attrs: { for: "email" } }, [_vm._v("Email Address")]),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "validate",
                  rawName: "v-validate",
                  value: "required|email",
                  expression: "'required|email'",
                },
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.forgotPasswordForm.email,
                  expression: "forgotPasswordForm.email",
                },
              ],
              staticClass: "form-control",
              attrs: {
                type: "text",
                name: "email",
                id: "email",
                autocomplete: "username",
              },
              domProps: { value: _vm.forgotPasswordForm.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.forgotPasswordForm, "email", $event.target.value)
                },
              },
            }),
            _vm._v(" "),
            _c(
              "span",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.errors.has("email"),
                    expression: "errors.has('email')",
                  },
                ],
                staticClass: "text-danger",
              },
              [
                _c("i", { staticClass: "fa fa-exclamation-circle" }),
                _vm._v("\n        " + _vm._s(_vm.errors.first("email"))),
              ]
            ),
          ]
        ),
        _vm._v(" "),
        _c("div", { staticClass: "form-group forgotPassword text-center" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-block btn-orange",
              attrs: {
                type: "submit",
                disabled: _vm.errors.any() || _vm.forgotPasswordForm.processing,
              },
            },
            [
              _vm._v("\n        Submit\n        "),
              _c("i", {
                staticClass: "fa",
                class: {
                  "fa-spinner fa-spin": _vm.forgotPasswordForm.processing,
                  "fa-arrow-right": !_vm.forgotPasswordForm.processing,
                },
                attrs: { "aria-hidden": "true" },
              }),
            ]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }