var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition-group",
    {
      style: `--header-offset: ${_vm.headerOffset}px`,
      attrs: { name: "list", tag: "div", id: "page-notifications" },
    },
    _vm._l(_vm.unreadNotifications, function (notification) {
      return _c(
        "div",
        {
          key: `notification-${notification.index}`,
          class: `list-item notification ${notification.type}`,
          on: {
            mouseenter: function ($event) {
              return _vm.pause(notification.index)
            },
            mouseleave: function ($event) {
              return _vm.start(notification.index)
            },
          },
        },
        [
          _c("div", { staticClass: "notification-accent" }, [
            _c("i", { class: `fa fa-${notification.icon}` }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "notification-content" }, [
            _c("strong", { staticClass: "notification-content-title" }, [
              _vm._v(_vm._s(notification.title || notification.type)),
            ]),
            _vm._v(" "),
            _c("p", { staticClass: "notification-content-body" }, [
              _c("span", {
                domProps: { innerHTML: _vm._s(notification.message) },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "button",
            {
              staticClass: "notification-close",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.remove(notification.index)
                },
              },
            },
            [_vm._v("×")]
          ),
        ]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }