var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-group" }, [
    _c(
      "p",
      {
        staticClass: "frmValidation",
        class: { "frmValidation--passed": _vm.newPassword.length > 7 },
      },
      [
        _c("i", {
          staticClass: "frmIcon fa",
          class: _vm.newPassword.length > 7 ? "fa-check" : "fa-times",
        }),
        _vm._v("\n    Longer than 7 characters\n  "),
      ]
    ),
    _vm._v(" "),
    _c(
      "p",
      {
        staticClass: "frmValidation",
        class: { "frmValidation--passed": _vm.has_uppercase },
      },
      [
        _c("i", {
          staticClass: "frmIcon fa",
          class: _vm.has_uppercase ? "fa-check" : "fa-times",
        }),
        _vm._v("\n    Has a capital letter\n  "),
      ]
    ),
    _vm._v(" "),
    _c(
      "p",
      {
        staticClass: "frmValidation",
        class: { "frmValidation--passed": _vm.has_lowercase },
      },
      [
        _c("i", {
          staticClass: "frmIcon fa",
          class: _vm.has_lowercase ? "fa-check" : "fa-times",
        }),
        _vm._v("\n    Has a lowercase letter\n  "),
      ]
    ),
    _vm._v(" "),
    _c(
      "p",
      {
        staticClass: "frmValidation",
        class: { "frmValidation--passed": _vm.has_number },
      },
      [
        _c("i", {
          staticClass: "frmIcon fa",
          class: _vm.has_number ? "fa-check" : "fa-times",
        }),
        _vm._v("\n    Has a number\n  "),
      ]
    ),
    _vm._v(" "),
    _c(
      "p",
      {
        staticClass: "frmValidation",
        class: { "frmValidation--passed": _vm.has_special },
      },
      [
        _c("i", {
          staticClass: "frmIcon fa",
          class: _vm.has_special ? "fa-check" : "fa-times",
        }),
        _vm._v("\n    Has a special character\n  "),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }