var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "homepageVideoModal",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "projectUploadLabel",
      },
    },
    [
      _c("div", { staticClass: "modal-dialog", attrs: { role: "document" } }, [
        _c("div", { staticClass: "modal-content" }, [
          _c("div", { staticClass: "modal-header" }, [
            _c(
              "button",
              {
                staticClass: "close",
                attrs: {
                  type: "button",
                  "data-dismiss": "modal",
                  "aria-label": "Close",
                },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.closeModal.apply(null, arguments)
                  },
                },
              },
              [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
            ),
            _vm._v(" "),
            _c("h4", { staticClass: "modal-title" }, [
              _vm._v("See Why Brands Trust Cerakote"),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [
            _c("section", [
              _c("iframe", {
                ref: "videoIframe",
                attrs: {
                  width: "100%",
                  height: "415",
                  src: _vm.videoUrl,
                  title: "YouTube video player",
                  frameborder: "0",
                  allow:
                    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
                  referrerpolicy: "strict-origin-when-cross-origin",
                  allowfullscreen: "",
                },
              }),
            ]),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }