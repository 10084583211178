var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("modal", { attrs: { id: "notificationPopup" } }, [
    _c(
      "button",
      {
        staticClass: "close close-popup",
        staticStyle: { top: "-15px", left: "15px" },
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close",
        },
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    ),
    _vm._v(" "),
    _vm.popup && _vm.popup.desktop_image_url
      ? _c("div", [
          _vm.$mq === "desktop" || _vm.$mq === "991"
            ? _c("div", [
                _vm.popup.target_url
                  ? _c("a", { attrs: { href: _vm.popup.target_url } }, [
                      _c("img", {
                        staticStyle: {
                          height: "auto",
                          display: "block",
                          width: "100%",
                        },
                        attrs: { src: _vm.popup.desktop_image_url },
                      }),
                    ])
                  : _c("img", {
                      staticStyle: {
                        height: "auto",
                        display: "block",
                        width: "100%",
                      },
                      attrs: { src: _vm.popup.desktop_image_url },
                    }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$mq === "767"
            ? _c("div", [
                _vm.popup.target_url
                  ? _c("a", { attrs: { href: _vm.popup.target_url } }, [
                      _c("img", {
                        staticStyle: {
                          height: "auto",
                          display: "block",
                          width: "100%",
                        },
                        attrs: { src: _vm.popup.tablet_image_url },
                      }),
                    ])
                  : _c("img", {
                      staticStyle: {
                        height: "auto",
                        display: "block",
                        width: "100%",
                      },
                      attrs: { src: _vm.popup.tablet_image_url },
                    }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.$mq === "600" || _vm.$mq === "479"
            ? _c("div", [
                _vm.popup.target_url
                  ? _c("a", { attrs: { href: _vm.popup.target_url } }, [
                      _c("img", {
                        staticStyle: {
                          height: "auto",
                          display: "block",
                          width: "100%",
                        },
                        attrs: { src: _vm.popup.mobile_image_url },
                      }),
                    ])
                  : _c("img", {
                      staticStyle: {
                        height: "auto",
                        display: "block",
                        width: "100%",
                      },
                      attrs: { src: _vm.popup.mobile_image_url },
                    }),
              ])
            : _vm._e(),
        ])
      : _vm.popup
      ? _c("div", { staticStyle: { padding: "20px" } }, [
          _vm.popup.target_url
            ? _c("a", { attrs: { href: _vm.popup.target_url } }, [
                _vm._v(_vm._s(_vm.popup.message)),
              ])
            : _c("span", [_vm._v(_vm._s(_vm.popup.message))]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }